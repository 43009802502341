/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { DeviceType } from '@scandipwa/scandipwa/src/type/Device.type';

import './TogglePopupLink.style';

/** @namespace Pwa/Component/TogglePopupLink/Component/TogglePopupLinkComponent */
export class TogglePopupLinkComponent extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        text: PropTypes.string,
        device: DeviceType.isRequired,
        showOverlay: PropTypes.func.isRequired,
        hideOverlay: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired
    };

    static defaultProps = { text: '' };

    renderPopUpToogler() {
        const { showPopup, id } = this.props;
        return (
          <span
            key={ id }
            block="TogglePopupLink"
            role="button"
            tabIndex="0"
            onClick={ showPopup }
          >
            { this.renderTogglerLabel() }
          </span>
        );
    }

    renderPopOverToggler() {
        const { showOverlay, hideOverlay, id } = this.props;
        return (
        <div
          key={ id }
          block="TogglePopupLink"
          role="button"
          tabIndex="0"
          onMouseEnter={ showOverlay }
          onMouseLeave={ hideOverlay }
        >
          { this.renderTogglerLabel() }
        </div>
        );
    }

    renderTogglerLabel() {
        const { text } = this.props;
        return (
            <>
              { text && (
                  <>
                  <span
                    block="TogglePopupLink"
                    elem="Label"
                  >
                    { text }
                  </span>
                  &nbsp;
                  </>
              ) }
              <i
                className="fa fa-info-circle"
                aria-hidden
              />
            </>
        );
    }

    render() {
        const { device } = this.props;

        if (device.isMobile) {
            return this.renderPopUpToogler();
        }

        return this.renderPopOverToggler();
    }
}

export default TogglePopupLinkComponent;
