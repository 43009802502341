/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Overlay from 'Component/Overlay';

import { CMS_OVERLAY } from './CmsOverlay.config';

import './CmsOverlay.style';

/** @namespace Pwa/Component/CmsOverlay/Component/CmsOverlayComponent */
export class CmsOverlayComponent extends PureComponent {
    static propTypes = {
        size: PropTypes.oneOf([
            'small',
            'medium',
            'large',
            'extra'
        ]).isRequired,
        cms_block: PropTypes.string.isRequired,
        id: PropTypes.string
    };

    static defaultProps = {
        id: CMS_OVERLAY
    };

    state = {
        visible: false
    };

    handleOnVisible = () => {
        this.setState({
            visible: true
        });
    };

    handleOnHide = () => {
        this.setState({
            visible: false
        });
    };

    render() {
        const { id, cms_block, size } = this.props;
        const { visible } = this.state;

        return (
            <Overlay
              id={ id }
              clickOutside
              mix={ { block: 'CmsOverlay', mods: { size } } }
              onVisible={ this.handleOnVisible }
              onHide={ this.handleOnHide }
            >
                { visible && <CmsBlock identifier={ cms_block } /> }
            </Overlay>
        );
    }
}

export default CmsOverlayComponent;
