/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent, Suspense } from 'react';

import Overlay from 'Component/Overlay';
import Popup from 'Component/Popup';
import PopupSuspense from 'Component/PopupSuspense';
import TogglePopupLink from 'Component/TogglePopupLink';
import { DeviceType } from '@scandipwa/scandipwa/src/type/Device.type';

import './McDimensioniPopup.style';

/** @namespace Pwa/Component/McDimensioniPopup/Component/McDimensioniPopupComponent */
export class McDimensioniPopupComponent extends PureComponent {
    static propTypes = {
        mcProductSizes: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired
            })
        ),
        // eslint-disable-next-line react/forbid-prop-types
        mix: PropTypes.object,
        device: DeviceType.isRequired,
        id: PropTypes.string.isRequired
    };

    static defaultProps = {
        mcProductSizes: null,
        mix: {}
    };

    renderOverlayFallback() {
        const { id } = this.props;
        return (
            <PopupSuspense
              actualOverlayKey={ id }
            />
        );
    }

    renderMcComposizioneTipoPopup() {
        const { id, device } = this.props;

        if (device.isMobile) {
            return (
                <Popup
                  id={ id }
                  isSidePopup={ false }
                  clickOutside
                >
                    { this.renderPopupContent() }

                </Popup>
            );
        }

        return (
            <Suspense fallback={ this.renderOverlayFallback() }>
                <Overlay id={ id }>
                    { this.renderPopupContent() }
                </Overlay>
            </Suspense>
        );
    }

    renderPopupToggler() {
        const { id } = this.props;
        return (
            <TogglePopupLink id={ id } text={ __('Product dimensions') } />
        );
    }

    renderPopupContent() {
        const { mcProductSizes } = this.props;
        return (
            <div block="McDimensioniPopup" elem="List">
                { mcProductSizes.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                    <div block="McDimensioniPopup" elem="Line" key={ i }>
                        <div block="McDimensioniPopup" elem="Name">
                            { item.label }
                        </div>
                        <div block="McDimensioniPopup" elem="Dimension">
                            { item.value }
                        </div>
                    </div>
                )) }
            </div>
        );
    }

    render() {
        const { mix } = this.props;
        return (
            <div block="McDimensioniPopup" mix={ mix }>
                { this.renderPopupToggler() }
                { this.renderMcComposizioneTipoPopup() }
            </div>
        );
    }
}

export default McDimensioniPopupComponent;
