/* eslint-disable react/forbid-prop-types */
// import PropTypes from 'prop-types';
import { PureComponent, Suspense } from 'react';

import CmsOverlay from 'Component/CmsOverlay/CmsOverlay.component';
import CmsPopup from 'Component/CmsPopup/CmsPopup.component';
import NoPropagationEvent from 'Component/NoPropagateEvents';
import PopupSuspense from 'Component/PopupSuspense';
import TogglePopupLink from 'Component/TogglePopupLink';
import { ProductType } from '@scandipwa/scandipwa/src/type/ProductList.type';
import { startMobile } from 'Util/mcMobileCheck';

/** @namespace Pwa/Component/ProductCardDetails/Elements/ProductComposizioneTipo/Component/ProductComposizioneTipoComponent */
export class ProductComposizioneTipoComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    renderOverlayFallback() {
        const { product: { sku } } = this.props;
        return (
            <PopupSuspense
              actualOverlayKey={ sku }
            />
        );
    }

    renderPopup() {
        const isMobile = startMobile();
        const { product: { sku, mc_composizione_tipo } } = this.props;
        const id_popup = `COMPOSIZIONI_TIPO_${ sku}`;

        if (isMobile) {
            return (
                <CmsPopup
                  id={ id_popup }
                  cms_block={ mc_composizione_tipo }
                />
            );
        }

        return (
            <Suspense fallback={ this.renderOverlayFallback() }>
                <CmsOverlay
                  size="small"
                  id={ id_popup }
                  cms_block={ mc_composizione_tipo }
                />
            </Suspense>
        );
    }

    render() {
        const { product: { sku, mc_composizione_tipo } } = this.props;
        const id_popup = `COMPOSIZIONI_TIPO_${ sku}`;

        if (!mc_composizione_tipo) {
            return null;
        }

        return (
            <NoPropagationEvent>
                <TogglePopupLink id={ id_popup } text={ __('Composizione tipo') } />
                { this.renderPopup() }
            </NoPropagationEvent>
        );
    }
}

export default ProductComposizioneTipoComponent;
