/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ChildrenType } from '@scandipwa/scandipwa/src/type/Common.type';

/** @namespace Pwa/Component/NoPropagateEvents/Container/NoPropagateEventsContainer */
export class NoPropagateEventsContainer extends PureComponent {
    static propTypes = {
        children: ChildrenType.isRequired,
        mix: PropTypes.object
    };

    static defaultProps = {
        mix: {}
    };

    noPropagateHandler = (e) => {
        e.stopPropagation();
    };

    render() {
        const { children, mix } = this.props;
        return (
            <div
              role="button"
              tabIndex="0"
              mix={ mix }
              onClick={ this.noPropagateHandler }
              onKeyDown={ this.noPropagateHandler }
            >
               { children }
            </div>
        );
    }
}

export default NoPropagateEventsContainer;
